import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import {
  getCertificates as getCertificatesService,
  Certificate,
  getCertificateById,
} from '../../services/certificate';
import {
  CertificatesContainer,
  CertificatesTable,
  CertificatesTableContainer,
  CertificatesTableRow,
  CertificatesTitle,
} from './style';
import ContentLoader from 'react-content-loader';
import CertDefaultImage from '../../assets/certificate.png';
import Container from '../../pdf/templates/Container';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import { ICertificateTemplateParsed } from '../../models/from-api/certificates';
import imgUrlToDataURL from '../../helpers/converters/imgUrlToDataURL';
import usePostHog from '../../hooks/usePosthog';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState([] as Certificate[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [certificateObject, setCertificateObject] = useState<any>({});
  const posthog = usePostHog();

  const getCompletedCourses = async () => {
    const localCompletedCourses = await getCertificatesService();

    if (localCompletedCourses && localCompletedCourses.length) {
      setCertificates(localCompletedCourses);
    } else {
      setShouldShowSkeleton(false);
    }
  };

  const getCertificateByIdFunction = async (course: Certificate) => {
    let certificate: any;
    
    try {
      certificate = await getCertificateById(course?.trail_id);
    } catch (e: any) {
      toast.error(e.response.data.message);
    }

    // transforms all imgs into dataURL/Blob.
    if (certificate) {
      const { parsedReplacedData } = Object.assign({}, certificate);

      const { preview, imagesArray } =
        parsedReplacedData as ICertificateTemplateParsed;
      parsedReplacedData.preview.src = await imgUrlToDataURL(preview.src);
      parsedReplacedData.imagesArray = await Promise.all(
        imagesArray.map(async img => {
          img.src = await imgUrlToDataURL(img.src);
          return img;
        }),
      );

      setCertificateObject({ parsedReplacedData });
    }

    const domElement = document.getElementById(
      `pdf-container-${certificate.parsedReplacedData.certificate_template_id}`,
    );
    html2canvas(domElement!, {
      onclone: document => {
        document.getElementById(`print-${course.trail_id}`)!.style.visibility =
          'hidden';
      },
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', [
        certificate?.parsedReplacedData?.width / 2,
        certificate?.parsedReplacedData?.height / 2,
      ]);

      try {
        pdf.addImage(
          imgData,
          'SVG',
          0,
          0,
          certificate?.parsedReplacedData?.width / 2,
          certificate?.parsedReplacedData?.height / 2,
        );
        pdf.save(
          `certificado-${course.trail_name}-${new Date().toLocaleDateString(
            'pt-BR',
          )}.pdf`,
        );
      } catch (e: any) {
        if (!certificateObject.parsedReplacedData) {
          toast.error('Curso não possui template de certificado!');
          return;
        }

        toast.error('Erro inesperado ao baixar certificado!');
      }
    });
  };

  useEffect(() => {
    posthog?.capture('Capacitação - Certificados');
  }, [posthog]);

  useEffect(() => {
    (async () => {
      await getCompletedCourses();
    })();
  }, []);

  return (
    <CertificatesContainer>
      <div
        className="content"
        style={{
          background: 'white',
          borderRadius: '5px',
          paddingTop: '5px',
          paddingBottom: '5px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <CertificatesTitle>Certificados</CertificatesTitle>

        <CertificatesTableContainer>
          <CertificatesTable>
            <thead>
              <tr>
                <th style={{ width: '40%' }}>Nome do Curso</th>
                <th style={{ width: '20%' }}>Autor</th>
                <th>Data de Conclusão</th>
                <th>Certificados</th>
              </tr>
            </thead>
            <tbody>
              {certificates && certificates.length ? (
                certificates.map(course => (
                  <CertificatesTableRow key={course.certificate_id}>
                    <td className="course-name">
                      <div className="course-image-container">
                        <img
                          src={course.imageUrl || CertDefaultImage}
                          alt="teste"
                        />
                      </div>
                      <div className="course-info">
                        <div className="course-category">{course.category}</div>
                        <div className="course-title">{course.trail_name}</div>
                      </div>
                    </td>
                    <td>{course.author}</td>
                    <td>
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(course.emitted_at),
                      )}
                    </td>
                    <td className="download-certificate">
                      <Container
                        certificateObject={
                          certificateObject?.parsedReplacedData
                        }
                      />
                      <button
                        id={`print-${course.trail_id}`}
                        onClick={() => getCertificateByIdFunction(course)}
                      >
                        <span>Baixar Certificado</span>
                        <AiOutlineDownload size={18} />
                      </button>
                    </td>
                  </CertificatesTableRow>
                ))
              ) : shouldShowSkeleton ? (
                Array(8)
                  .fill('')
                  .map((item, index) => (
                    <tr key={index}>
                      <td colSpan={4}>
                        <ContentLoader
                          backgroundColor="#e3e3e3"
                          style={{ width: '100%', height: '60px' }}
                        >
                          <rect
                            style={{ width: '100%', height: '60px' }}
                            rx="5"
                            ry="5"
                          />
                        </ContentLoader>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: 'center', padding: '15px' }}
                  >
                    Nenhum curso encontrado!
                  </td>
                </tr>
              )}
            </tbody>
          </CertificatesTable>
        </CertificatesTableContainer>
      </div>
    </CertificatesContainer>
  );
};

export default Certificates;
